import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { LinkCreate } from '../link';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Paper,
  Box,
  Button,
} from '@mui/material';
import { useState } from 'react';

type LinkProps = {
  onSubmit: (
    value: LinkCreate,
    helpers: FormikHelpers<LinkCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreateLinkForm = ({ onSubmit, submitting }: LinkProps) => {
  const [prot, setProt] = useState('https://');
  const navigate = useNavigate();

  const handleSubmit = async (
    value: LinkCreate,
    helpers: FormikHelpers<LinkCreate>
  ) => {
    const success = await onSubmit(
      {
        ...value,
        url: `${prot}${value.url}`,
      },
      helpers
    );
    if (success) {
      navigate('/', { replace: true });
      navigate('/links/create', { replace: true });
    }
  };

  const initialValues: LinkCreate = { title: '', url: '', category: '' };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder=""
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                  display="flex"
                >
                  <Grid item alignSelf="flex-start" sx={{ mr: 1 }}>
                    <FormControl sx={{ minWidth: 60 }}>
                      <InputLabel>Scheme</InputLabel>
                      <Select
                        value={prot}
                        size="small"
                        label="Scheme"
                        onChange={(e) => {
                          setProt(e.target.value);
                        }}
                      >
                        <MenuItem value="https://">https://</MenuItem>
                        <MenuItem value="http://">http://</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="url"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Url"
                      placeholder=""
                      value={formik.values.url}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.url)}
                      helperText={formik.errors.url}
                    />
                    {Boolean(formik.errors.url) && (
                      <FormHelperText>{formik.errors.url}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl
                      sx={{ minWidth: 120 }}
                      fullWidth
                      error={Boolean(formik.errors.category)}
                    >
                      <InputLabel>Category</InputLabel>
                      <Select
                        value={formik.values.category}
                        size="small"
                        label="Category"
                        onChange={formik.handleChange}
                        name="category"
                      >
                        <MenuItem value="Government Organizations">
                          Government Organizations
                        </MenuItem>
                        <MenuItem value="Network">Network</MenuItem>
                        <MenuItem value="Organizations">Organizations</MenuItem>
                        <MenuItem value="Media">Media</MenuItem>
                        <MenuItem value="Research Centers">
                          Research Centers
                        </MenuItem>
                        <MenuItem value="Member Organizations">
                          Member Organizations
                        </MenuItem>
                        <MenuItem value="Partner Organizations">
                          Partner Organizations
                        </MenuItem>
                        <MenuItem value="Internal Links">
                          Internal Links
                        </MenuItem>
                      </Select>
                      {Boolean(formik.errors.category) && (
                        <FormHelperText>
                          {formik.errors.category}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateLinkForm;
