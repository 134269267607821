import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ImpactsCreate, Impacts } from '../impacts';
import { Grid, TextField, Paper, Box, Button } from '@mui/material';

type ImpactsProps = {
  onSubmit: (
    value: ImpactsCreate,
    helpers: FormikHelpers<ImpactsCreate>
  ) => Promise<boolean>;
  submitting: boolean;
  impacts: Impacts | null;
};

const CreateImpactsForm = ({ onSubmit, submitting, impacts }: ImpactsProps) => {
  const navigate = useNavigate();

  const handleSubmit = async (
    value: ImpactsCreate,
    helpers: FormikHelpers<ImpactsCreate>
  ) => {
    const success = await onSubmit(value, helpers);
    if (success) {
      navigate('/', { replace: true });
      navigate('/impacts', { replace: true });
    }
  };

  const initialValues: ImpactsCreate = !impacts
    ? {
        beneficiaries: 0,
        raised: '',
        currency: '',
        income_raised: 0,
        home_donated: '',
      }
    : {
        beneficiaries: impacts.beneficiaries,
        raised: impacts.raised,
        currency: impacts.currency,
        income_raised: impacts.income_raised,
        home_donated: impacts.home_donated,
      };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid container justifyContent="space-between" spacing={1}>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="beneficiaries"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Beneficiaries"
                      placeholder=""
                      value={formik.values.beneficiaries}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.beneficiaries)}
                      helperText={formik.errors.beneficiaries}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="raised"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Raised"
                      placeholder=""
                      value={formik.values.raised}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.raised)}
                      helperText={formik.errors.raised}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="currency"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Currency"
                      placeholder=""
                      value={formik.values.currency}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.currency)}
                      helperText={formik.errors.currency}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="income_raised"
                      size="small"
                      type="number"
                      variant="outlined"
                      label="Income Raised"
                      placeholder=""
                      value={formik.values.income_raised}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.income_raised)}
                      helperText={formik.errors.income_raised}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="home_donated"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Homes Donated"
                      placeholder=""
                      value={formik.values.home_donated}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.home_donated)}
                      helperText={formik.errors.home_donated}
                    />
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateImpactsForm;
