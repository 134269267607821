import { FormikHelpers, useFormik } from 'formik';
import { LinkEdit, Link } from '../link';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Paper,
  Box,
  Button,
} from '@mui/material';

type LinkProps = {
  onSubmit: (
    value: LinkEdit,
    helpers: FormikHelpers<LinkEdit>
  ) => Promise<void>;
  submitting: boolean;
  link: Link;
};

const EditLinkForm = ({ onSubmit, submitting, link }: LinkProps) => {
  const handleSubmit = async (
    value: LinkEdit,
    helpers: FormikHelpers<LinkEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: LinkEdit = {
    title: link.title,
    url: link.url,
    category: link.category,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder=""
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl
                      sx={{ minWidth: 120 }}
                      error={Boolean(formik.errors.category)}
                    >
                      <InputLabel>Category</InputLabel>
                      <Select
                        value={formik.values.category}
                        size="small"
                        label="Category"
                        onChange={formik.handleChange}
                        name="category"
                      >
                        <MenuItem value="Government Organizations">
                          Government Organizations
                        </MenuItem>
                        <MenuItem value="Network">Network</MenuItem>
                        <MenuItem value="Organizations">Organizations</MenuItem>
                        <MenuItem value="Media">Media</MenuItem>
                        <MenuItem value="Research Centers">
                          Research Centers
                        </MenuItem>
                        <MenuItem value="Member Organizations">
                          Member Organizations
                        </MenuItem>
                        <MenuItem value="Partner Organizations">
                          Partner Organizations
                        </MenuItem>
                        <MenuItem value="Internal Links">
                          Internal Links
                        </MenuItem>
                      </Select>
                      {Boolean(formik.errors.category) && (
                        <FormHelperText>
                          {formik.errors.category}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="url"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Url"
                      placeholder=""
                      value={formik.values.url}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.url)}
                      helperText={formik.errors.url}
                    />
                  </Grid>
                </Grid>

                <Grid container sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default EditLinkForm;
