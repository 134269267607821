
import { FormikHelpers, useFormik } from "formik";
import { PublicationEdit, Publication } from "../publication";
import { Grid, TextField, FormControl, FormControlLabel, FormHelperText, Checkbox, Box, Paper, Button } from "@mui/material";


type PublicationProps = {
  onSubmit: (
    value: PublicationEdit,
    helpers: FormikHelpers<PublicationEdit>
  ) => Promise<void>;
  submitting: boolean;
  publication: Publication;
};

const EditPublicationForm = ({
  onSubmit,
  submitting,
  publication
}: PublicationProps) => {
  const handleSubmit = async (
    value: PublicationEdit,
    helpers: FormikHelpers<PublicationEdit>
  ) => {
    await onSubmit(value, helpers);
  };

  const initialValues: PublicationEdit =  {
    title: publication.title,
    other: !!publication.other,
    file: null,

  }

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    
<>
  <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        
<Grid item>
  <Paper sx={{ p: 2, pb: 3 }}>
  <Grid container justifyContent="space-between" spacing={1} >
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <TextField
    fullWidth
    name="title"
    size="small"
    type="text"
    variant="outlined"
    label="Title"
    placeholder=""
    value={formik.values.title}
    onChange={formik.handleChange}
    error={Boolean(formik.errors.title)}
    helperText={formik.errors.title}
  />
</Grid>

<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <FormControl error>
    <FormControlLabel
      control={<Checkbox name="other" checked={formik.values.other} onChange={formik.handleChange} />}
      label="Other"
    />
    { Boolean(formik.errors.other) && <FormHelperText>{formik.errors.other}</FormHelperText> }
  </FormControl>
</Grid>
</Grid><Grid container justifyContent="space-between" spacing={1} >
<Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
  <Grid item xs={12}>
    File
  </Grid>
  <Box>
    <input
      type="file"
      onChange={({ target: { files } }) => {
        formik.setFieldValue(
          'file',
          files && files.length ? files[0] : null
        );
      }}
      name="file"
    />
  </Box>
  {formik.touched.file && formik.errors.file && (
    <FormHelperText error>
      {formik.errors.file}
    </FormHelperText>
  )}
</Grid>
</Grid>

    <Grid container sx={{ mt: 2 }}>
      <Button
        type="submit"
        variant="contained"
        disabled={submitting}
        size="small"
      >
        {submitting ? 'Saving' : 'Save'}
      </Button>
    </Grid>
  </Paper>
</Grid>

      </Grid>
    </form>
  </Box>
</>
  
  );
};

export default EditPublicationForm;
