import { FormikHelpers, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ProjectsCreate } from '../projects';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Box,
  Paper,
  Button,
  IconButton,
} from '@mui/material';
import { Add, RemoveCircle } from '@mui/icons-material';
import { useState } from 'react';

type ProjectsProps = {
  onSubmit: (
    value: ProjectsCreate,
    helpers: FormikHelpers<ProjectsCreate>
  ) => Promise<boolean>;
  submitting: boolean;
};

const CreateProjectsForm = ({ onSubmit, submitting }: ProjectsProps) => {
  const [sponsors, setSponsors] = useState<
    { name: string; image: File | null }[]
  >([]);
  const navigate = useNavigate();

  const handleSubmit = async (
    value: ProjectsCreate,
    helpers: FormikHelpers<ProjectsCreate>
  ) => {
    const success = await onSubmit(
      {
        ...value,
        sponsorImages: sponsors.map((i) => i.image),
        sponsorNames: sponsors.map((i) => i.name),
      } as any,
      helpers
    );
    if (success) {
      navigate('/', { replace: true });
      navigate('/projects/create', { replace: true });
    }
  };

  const initialValues: ProjectsCreate = {
    title: '',
    category: '',
    description: '',
    sitemap: '',
    cover: null,
    sponsorImages: [],
    sponsorNames: [],
  };

  const formik = useFormik({
    initialValues,
    onSubmit: handleSubmit,
  });

  // console.log('Edit Form', initialValues);

  return (
    <>
      <Box sx={{ flexGrow: 1, maxWidth: 800 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item>
              <Paper sx={{ p: 2, pb: 3 }}>
                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="title"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Title"
                      placeholder=""
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.title)}
                      helperText={formik.errors.title}
                    />
                  </Grid>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <FormControl
                      sx={{ minWidth: 120 }}
                      error={Boolean(formik.errors.category)}
                    >
                      <InputLabel>Category</InputLabel>
                      <Select
                        value={formik.values.category}
                        size="small"
                        label="Category"
                        onChange={formik.handleChange}
                        name="category"
                      >
                        <MenuItem value="inprogress">In Progress</MenuItem>
                        <MenuItem value="completed">Completed</MenuItem>
                      </Select>
                      {Boolean(formik.errors.category) && (
                        <FormHelperText>
                          {formik.errors.category}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="description"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Description"
                      placeholder=""
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.description)}
                      helperText={formik.errors.description}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <TextField
                      fullWidth
                      name="sitemap"
                      size="small"
                      type="text"
                      variant="outlined"
                      label="Sitemap"
                      placeholder="https://www.google.com/maps/embed"
                      value={formik.values.sitemap}
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.sitemap)}
                      helperText={formik.errors.sitemap}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={1}
                  sx={{ mt: 2 }}
                >
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Cover Image
                    </Grid>
                    <Box>
                      <input
                        type="file"
                        onChange={({ target: { files } }) => {
                          formik.setFieldValue(
                            'cover',
                            files && files.length ? files[0] : null
                          );
                        }}
                        name="cover"
                      />
                    </Box>
                    {formik.touched.cover && formik.errors.cover && (
                      <FormHelperText error>
                        {formik.errors.cover}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>

                <Grid container spacing={1} sx={{ mt: 2 }}>
                  <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                      Project Sponsors
                    </Grid>
                  </Grid>
                  <Grid item sx={{ ml: 1 }}>
                    <IconButton
                      onClick={() => {
                        setSponsors([...sponsors, { name: '', image: null }]);
                      }}
                    >
                      <Add color="primary" />
                    </IconButton>
                  </Grid>
                </Grid>

                {sponsors.map((sponsor, i) => (
                  <Grid
                    key={`sponsor-${i}`}
                    container
                    display="flex"
                    alignItems="center"
                    spacing={1}
                    sx={{ mt: 1 }}
                  >
                    <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                      <TextField
                        fullWidth
                        size="small"
                        type="text"
                        variant="outlined"
                        label="Name"
                        placeholder=""
                        value={sponsor.name}
                        onChange={(e) => {
                          const curSponsors = [...sponsors];
                          curSponsors[i].name = e.target.value;
                          setSponsors(curSponsors);
                        }}
                        required
                      />
                    </Grid>
                    <Grid item alignSelf="flex-start" sx={{ flexGrow: 1 }}>
                      <input
                        required
                        type="file"
                        onChange={({ target: { files } }) => {
                          const curSponsors = [...sponsors];
                          curSponsors[i].image =
                            files && files.length ? files[0] : null;
                        }}
                        name="image"
                      />
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => {
                          const curSponsors = [...sponsors];
                          curSponsors.splice(i, 1);
                          setSponsors(curSponsors);
                        }}
                      >
                        <RemoveCircle />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                {formik.errors.sponsorImages && (
                  <FormHelperText error>
                    {JSON.stringify(formik.errors.sponsorImages)}
                  </FormHelperText>
                )}
                {formik.errors.sponsorNames && (
                  <FormHelperText error>
                    {JSON.stringify(formik.errors.sponsorNames)}
                  </FormHelperText>
                )}

                <Grid container sx={{ mt: 2 }}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    size="small"
                  >
                    {submitting ? 'Saving' : 'Save'}
                  </Button>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

export default CreateProjectsForm;
